import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import { isMobile } from "react-device-detect";
import tabLogo from "../../assets/pure-tab-with-logo.png";
import mobile from "../../assets/pure-mobile-with-logo.png";
import { useNavigate } from "react-router-dom";
import "./ProductPage.css"; // Make sure to create and link this CSS file

const ProductPage = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleExpand = (card) => {
    setExpandedCard(expandedCard === card ? null : card);
  };

  const gridStyle = {
    display: "grid",
    width: isMobile ? "100%" : "70rem",
    gridGap: isMobile ? "1rem" : "3rem",
    gridTemplateColumns: isMobile
      ? "repeat(auto-fit, minmax(20rem, 1fr))"
      : "repeat(auto-fit, minmax(30rem, 1fr))",
    padding: isMobile ? "1rem" : "0",
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: ".4rem",
    overflow: "hidden",
    boxShadow: "0 1rem 2rem rgba(0, 0, 0, .1)",
    cursor: "pointer",
    transition: ".2s",
    width: isMobile ? "90%" : "100%",
    margin: isMobile ? "0 auto" : "0",
  };

  const cardImgStyle = {
    display: "block",
    width: "100%",
    height: isMobile ? "8rem" : "10rem",
    paddingTop: isMobile ? "20px" : "35px",
  };

  const cardContentStyle = {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    gridRowGap: isMobile ? "1rem" : "2rem",
    padding: isMobile ? "1rem" : "2rem",
    height: "100%",
  };

  const cardHeaderStyle = {
    fontSize: isMobile ? "1.2rem" : "1.5rem",
    fontWeight: "500",
    color: "#0d0d0d",
  };

  const cardTextStyle = {
    fontSize: isMobile ? "1rem" : "1.2rem",
    letterSpacing: ".1rem",
    lineHeight: "1.7",
    color: "#3a3a3a",
    marginBottom: "2.5rem",
    fontWeight: "500",
    textAlign: "center", // Center-align the text
  };

  const cardListStyle = {
    fontSize: isMobile ? "0.8rem" : "1rem",
    letterSpacing: ".1rem",
    color: "#3a3a3a",
    fontWeight: "500",
    textAlign: "center", // Center-align the text
  };

  const cardBtnStyle = {
    display: "block",
    width: "100%",
    padding: isMobile ? "1rem" : "1rem",
    fontSize: isMobile ? "1rem" : "1rem",
    textAlign: "center",
    color: "#3363ff",
    backgroundColor: "#e6ecff",
    border: "none",
    borderRadius: ".4rem",
    transition: ".2s",
    cursor: "pointer",
    marginBottom: "0.5rem", // Small margin at the bottom for spacing
  };

  const smallBtnStyle = {
    display: "block",
    width: "30%",
    margin: "0 auto 0.2rem auto", // Center-align the button with small bottom margin
    padding: "0.5rem",
    fontSize: "0.9rem",
    textAlign: "center",
    color: "#3363ff",
    backgroundColor: "#e6ecff",
    border: "none",
    borderRadius: ".4rem",
    transition: ".2s",
    cursor: "pointer",
  };

  const titleStyle = {
    marginTop: "100px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "calc(16px + 2vw)", // Responsive font size
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means this effect runs only on mount

  const navigate = useNavigate(); // Initialize useNavigate
  const navigateToMobileSelection = () => navigate("/mobile-selection");
  const navigateToTabSelection = () => navigate("/tab-selection");
  const navigateToMusicPlayerSelection = () => navigate("/max-music");
  const navigateToNovaSelection = () => navigate("/nova-selection");

  return (
    <>
      <h1 style={titleStyle}>Choose Kosher Device</h1>

      <div
        style={{
          padding: "1rem",
          backgroundColor: "",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div style={gridStyle}>
          <div style={cardStyle}>
            <img src={mobile} alt="G65 Smartphone" style={cardImgStyle} />
            <div style={cardContentStyle}>
              <h1 style={cardHeaderStyle}>G65 Smartphone</h1>
              <div>
                <p style={cardTextStyle}>
                  Price:{" "}
                  <span
                    style={{ textDecoration: "line-through", color: "red" }}
                  >
                    $350
                  </span>{" "}
                  $99.99 or $9.99/month for 12 months
                </p>
                <div style={cardListStyle}>
                  <p>Customized OS</p>
                  <p>
  Sim-Locked to Device <span style={{ color: 'blue', fontWeight: 'bold' }}>(T-Mobile Only)</span>
</p>

                  <p>Kosher App Store</p>
                  <p>Powered by Android 13</p>
                </div>
              </div>

              <button onClick={navigateToMobileSelection} style={cardBtnStyle}>
                Choose Device <span>&rarr;</span>
              </button>
            </div>
          </div>

          <div style={cardStyle}>
            <img src={tabLogo} alt="G10 Tablet" style={cardImgStyle} />
            <div style={cardContentStyle}>
              <h1 style={cardHeaderStyle}>G10 Tablet</h1>
              <div>
                <p style={cardTextStyle}>
                  Price:{" "}
                  <span
                    style={{ textDecoration: "line-through", color: "red" }}
                  >
                    $350
                  </span>{" "}
                  $99.99 or $9.99/month for 12 months
                </p>
                <div style={cardListStyle}>
                  <p>Customized OS</p>
                  <p>Sim-Locked to Device</p>
                  <p>Kosher App Store</p>
                  <p>Powered by Android 13</p>
                </div>
              </div>

              <button onClick={navigateToTabSelection} style={cardBtnStyle}>
                Choose Device <span>&rarr;</span>
              </button>
            </div>
          </div>

          <div style={cardStyle}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2FMax%20music_with%20apps-med-min.png?alt=media&token=6227d429-d5f7-4c78-a502-68cf729a3d76"
              alt="Max Music"
              style={cardImgStyle}
            />
            <div style={cardContentStyle}>
              <h1 style={cardHeaderStyle}>Max Music</h1>
              <div>
                <p style={cardTextStyle}>
                  Price:{" "}
                  <span
                    style={{ textDecoration: "line-through", color: "red" }}
                  >
                    $150
                  </span>{" "}
                  $99.99 or $9.99/month for 12 months
                </p>
                <div style={cardListStyle}>
                  <p>24Six, Zing</p>
                  <p>Naki Go, J Stream</p>
                  <p>& many more</p>
                </div>
              </div>

              <button
                onClick={navigateToMusicPlayerSelection}
                style={cardBtnStyle}
              >
                Choose Device <span>&rarr;</span>
              </button>
            </div>
          </div>

          <div style={cardStyle}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/kosher-web.appspot.com/o/kosher-images%2Frandom-imgs%2Foutput-onlinepngtools.png?alt=media&token=63ea2af3-cacd-47a1-a9ea-7315b508037a"
              alt="Nova"
              style={cardImgStyle}
            />
            <div style={cardContentStyle}>
              <h1 style={cardHeaderStyle}>Nova</h1>
              <div>
                <p style={cardTextStyle}>
                  Price:{" "}
                  <span
                    style={{ textDecoration: "line-through", color: "red" }}
                  >
                    $350
                  </span>{" "}
                  $99.99 or $9.99/month for 12 months
                </p>
                <div style={cardListStyle}>
                  <p>Customized OS</p>
                  <p>
                    Sim-Locked to Device{" "}
                    <span style={{ color: "blue", fontWeight: "bold" }}>
                      (T-Mobile and AT&T)
                    </span>
                  </p>
                  <p>Kosher App Store</p>
                  <p>Powered by Android 13</p>
                  <p>13P MP Camera</p>
                </div>
              </div>

              <div
                className={`details ${
                  expandedCard === "Nova" ? "expanded" : ""
                }`}
              >
                {expandedCard === "Nova" && (
                  <div style={{ padding: "1rem", textAlign: "left" }}>
                    <p>Display: 6.5-inch AMOLED</p>
                    <p>Camera: 108 MP Quad Camera</p>
                    <p>Battery: 4500mAh</p>
                    <p>RAM: 8GB</p>
                    <p>Storage: 256GB</p>
                  </div>
                )}
              </div>
              <button onClick={navigateToNovaSelection} style={cardBtnStyle}>
                Choose Device <span>&rarr;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;
